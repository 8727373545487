import { ReferralTransactionType } from "@/utils/api-client";

export default {
  [ReferralTransactionType.BORROWER_LOAN_ISSUED]: "Привлечение заемщика",
  [ReferralTransactionType.LENDER_IDENTIFIED]: "Привлечение инвестора",
  [ReferralTransactionType.LENDER_DIVIDEND_RECEIVED]: "Инвестиция",
  [ReferralTransactionType.LENDER_INVESTED]: "Инвестиция на первичном рынке",
  [ReferralTransactionType.LENDER_PURCHASED]: "Инвестиция на вторичном рынке",
  [ReferralTransactionType.WITHDRAW]: "Вывод средств"
};
